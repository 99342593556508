@import 'variables';

.App {
  font-family: 'Raleway', sans-serif;
  font-display: swap;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .sr-only {
    opacity: 0;
    height: 0;
    width: 0;
    position: absolute;
  }
  a {
    &:hover {
      text-decoration: underline;
    }
    color: $mainColor;
    // text-decoration: none;
  }
}
