@import 'variables';

.App .TopSection {
  color: white;
  background-color: $mainColor;
  min-height: 98vh;
  @media (max-aspect-ratio: 1/1) {
    min-height: 90vh;
  }
  display: flex;
  flex-direction: column;
  .landing {
    flex: 1;
    display: flex;
    justify-content: space-around;
    @supports not (-ms-ime-align: auto) {
      justify-content: space-evenly;
    }
    align-items: center;
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
    }
    .info {
      flex: 1;
      margin: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      @supports not (-ms-ime-align: auto) {
        justify-content: space-evenly;
      }
      @media only screen and (max-width: 1024px) {
        margin-bottom: 0;
        flex: 0;
      }

      h1 {
        padding: 0.5rem 0.5rem 0 0.5rem;
        letter-spacing: 0.3rem;
        margin: 0;
        font-weight: 700;
        font-size: 2.5rem;
        text-transform: uppercase;
        @media only screen and (max-width: 1024px) {
          font-size: 1.8rem;
        }
      }
      h2 {
        padding: 0 0.5rem 2rem 0.5rem;
        letter-spacing: 0.2rem;
        margin: 0;
        font-weight: 400;
        font-size: 0.8rem;
        text-transform: uppercase;
        border-bottom: 2px solid white;
      }
      .nav {
        list-style-type: none;
        margin: 0;
        padding: 2rem 0;
        text-align: right;
        letter-spacing: 0.05rem;
        @media only screen and (max-width: 1024px) {
          font-size: 0.8rem;
          padding: 0.5rem 0;
        }
        .navItem {
          padding: 0.3rem 0;
          a {
            padding: 0.1rem;
            color: white;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .youtube {
      position: relative;
      margin: 2rem;
      width: 50vw;
      height: 0;
      padding-bottom: calc(50vw * 9 / 16);
      @media only screen and (max-width: 1024px) {
        width: 85vw;
        padding-bottom: calc(85vw * 9 / 16);
      }
      iframe {
        z-index: 1;
      }
      iframe,
      img {
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .preProducts {
    .products {
      display: flex;
      justify-content: center;
      h3 {
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        font-size: 0.9rem;
        color: $dark;
        margin: 0;
        padding: 0.6rem 2rem;
        background-color: white;
        border-radius: 1rem 1rem 0 0;
        box-shadow: 0 -0.3rem 0.4rem rgba(0, 0, 0, 0.2);
        z-index: 0;
        a {
          text-decoration: none;
          color: $dark;
        }
      }
    }
  }
}
