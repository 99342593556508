@import 'variables';

.Content {
  color: $dark;
  .steps {
    padding: 4rem 2rem;
    display: flex;
    justify-content: space-around;
    @supports not (-ms-ime-align: auto) {
      justify-content: space-evenly;
    }
    align-items: flex-start;
    @media only screen and (max-width: 1100px) {
      padding: 2rem 3rem;
      align-items: center;
      flex-direction: column;
    }
  }
  #singleSteps {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 2rem 2rem;
    flex-wrap: wrap;
    @media only screen and (max-width: 1100px) {
      margin: 1rem 2rem;
    }
    @media only screen and (max-width: 800px) {
      flex-direction: column;
    }
    .step {
      width: 50%;
      margin: 2rem 0;
      .stepDescription {
        margin-right: 1.8rem;
      }
      @media only screen and (max-width: 1100px) {
        padding: 1rem 0;
        .stepContent {
          padding: 0 1rem;
          flex-direction: column;
        }
        h3 {
          text-align: center;
        }
        .stepDescription {
          margin: 2rem 0 0;
        }
        .stepImage {
          margin-top: 1rem;
        }
      }
      @media only screen and (max-width: 800px) {
        width: 100%;
        .stepDescription {
          padding: 0;
        }
        margin: 1rem 0;
      }
    }
  }
  .step {
    width: 33%;
    margin: 0 1rem;
    @media only screen and (max-width: 1100px) {
      width: 100%;
      padding: 2rem 0;
    }
    h3 {
      color: $mainColor;
      font-size: 1.5rem;
      margin-top: 0;
      @media only screen and (max-width: 1300px) {
        font-size: 1.3rem;
      }
      @media only screen and (max-width: 1100px) {
        font-size: 1.5rem;
      }
      @media only screen and (max-width: 600px) {
        font-size: 1.2rem;
      }
    }
    .stepContent {
      display: flex;
      align-items: center;
      .stepImage {
        &.linkable {
          cursor: pointer;
        }
        max-width: 20vw;
        max-height: 20vw;
        width: 15rem;
        height: 15rem;
        margin: auto;
        background-size: cover;
        border-radius: 50%;
        background-position: right;
        box-shadow: inset 3px 3px 6px black;
        transition: border-radius 0.5s ease;
        &:hover {
          border-radius: 3%;
        }
        @media only screen and (max-width: 1500px) {
          max-width: 10vw;
          max-height: 10vw;
        }
        @media only screen and (max-width: 1100px) {
          max-width: 30vw;
          max-height: 30vw;
        }
      }
      .stepDescription {
        flex: 1;
        line-height: 1.5rem;
        margin: 0 0 0 1.8rem;
        padding: 0;
        font-size: 0.95rem;
        @media only screen and (max-width: 1100px) {
          padding-right: 0;
        }
        @media only screen and (max-width: 600px) {
          line-height: 1.2rem;
        }
      }
    }
  }
  .compatible {
    margin: 0;
    padding: 3rem;
    text-align: center;
    background-color: $mainColor;
    color: white;
    box-shadow: inset 0 0.3rem 0.4rem rgba(0, 0, 0, 0.2);
    h2 {
      margin-top: 0;
    }
    p {
      margin-bottom: 0;
    }
  }
}
