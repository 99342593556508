@import 'variables';

.App .Footer {
  text-align: center;
  background-color: $mainColor;
  color: white;
  box-shadow: inset 0 0.3rem 0.4rem rgba(0, 0, 0, 0.2);
  font-size: 0.8rem;
  padding: 0.5rem;
  a {
    color: white;
  }
}
