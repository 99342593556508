@import 'variables';

.ImageOverlay {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 2;
  transition: opacity 0.15s linear;
  opacity: 1;
  &.transition {
    opacity: 0;
  }
  .bg {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(0.2rem);
    background-color: rgba(0, 0, 0, 0.13);
  }
  button {
    position: fixed;
    right: 2rem;
    top: 2rem;
    z-index: 3;
    color: $dark;
    background: $secondColor;
    border: none;
    padding: 1rem;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.466);
    &:hover {
      opacity: 0.7;
    }
  }
  img {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    max-width: 80vw;
    max-height: 80vh;
  }
}
